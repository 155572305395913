import { AvatarGroupClassKey, Box, Typography } from "@mui/material";
import { CompanySelectorWrapper } from "./style";
import DurationsList from "../../Calendar/TimeSelector/DurationsList";
import SlotsList from "../../Calendar/TimeSelector/SlotsList";
import {
  AvailableStartingTimesDuration,
  AvailableStartingTimesResource,
  AvailableStartingTimesSlot,
} from "../../../hooks/useAvailableStartingTimes";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCart from "../../../hooks/useCart";
import { toast } from "react-toastify";
import {
  areAllOffPeakSlots,
  getPriceCard,
  getTimezone,
  isAfterCutOff,
} from "../../../Utils/format";
import PlaceIcon from "@mui/icons-material/Place";
import CheckoutContextProvider, {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import dummyImage from "../../../assets/images/dsw.jpg";
interface IProps {
  availableStartingTimes: AvailableStartingTimesResource;
  slotsMap: Map<string, AvailableStartingTimesSlot[]>;
}
const CompanySelector: React.FC<IProps> = ({
  availableStartingTimes,
  slotsMap,
}) => {
  const navigate = useNavigate();

  const { marketplaceName, company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const [selectedDuration, setSelectedDuration] = useState<
    AvailableStartingTimesDuration | undefined
  >(
    availableStartingTimes.duration?.length > 0
      ? availableStartingTimes.duration[0]
      : undefined
  );

  const [selectedSlot, setSelectedSlot] = useState<
    AvailableStartingTimesSlot | undefined
  >();

  const bookingSlots = slotsMap.get(selectedDuration?.id!)?.filter((slot) => {
    const service = availableStartingTimes.service;
    const isAvailable = slot.available > 0;
    const isAfterCutoff = isAfterCutOff(
      service?.cutoff,
      slot.fullDate,
      getTimezone(availableStartingTimes.timezone)
    );

    const isServiceActive = service?.hasOwnProperty("isActive")
      ? service.isActive
      : true;
    return isAvailable && isAfterCutoff && isServiceActive;
  });

  const { addToCart } = useCart();

  useEffect(() => {
    setSelectedDuration(
      availableStartingTimes.duration?.length > 0
        ? availableStartingTimes.duration[0]
        : undefined
    );
  }, [availableStartingTimes?.duration]);

  const handleAddToCart = async () => {
    const service = availableStartingTimes.service;
    const addedToCart = await addToCart({
      selectedService: service!.id,
      selectedDuration,
      selectedSlot,
      quantity: 1,
      selectedSession: undefined,
      addonsList: [],
      childrenInformation: [],
      selectedResource: undefined,
      timezone: availableStartingTimes.timezone,
    });
    if (addedToCart) {
      window.dataLayer?.push({
        event: "add_to_cart",
        ecommerce: { ...selectedSlot },
      });

      setSelectedSlot(undefined);
      navigate(
        `/CartCheckout?companyId=${service?.companyId}&marketplaceCompanyId=${company?.id}&marketplaceName=${marketplaceName}`
      );
    } else {
      toast("Error while adding the item to the cart", {
        theme: "colored",
        type: "error",
      });
    }
  };

  return !!availableStartingTimes.companyName ? (
    <CompanySelectorWrapper>
      {/* <Box
        sx={{
          width: "100%",
        }}
      >
        <img
          src={dummyImage}
          style={{
            width: "100%",
            height: "206px",
            borderRadius: "16px",
            boxShadow: "0px 6px 40px 0px rgba(208, 208, 208, 0.2)",
          }}
          alt="Service"
        />
      </Box> */}
      <Box className="header">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={availableStartingTimes.companyLogo}
            style={{ width: "80px", height: "74px", marginRight: "15px" }}
            alt="sport"
          />
        </Box>
        <Box className="description">
          <Box className="companyName">
            {availableStartingTimes.companyName}
          </Box>

          <Box>
            <Typography className="location">
              {" "}
              <PlaceIcon style={{ color: "red", fontSize: "15px" }} />{" "}
              {availableStartingTimes.area}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="availability">
        <DurationsList
          availableStartingTimes={availableStartingTimes}
          selectedDuration={selectedDuration}
          setSelectedDuration={setSelectedDuration}
        />

        <Box
          className="price"
          sx={{
            fontFamily: "Inter",
            fontSize: "16px !important",
            fontWeight: 500,
            lineHeight: "18px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px !important",
              fontWeight: 500,
              lineHeight: "18px",
            }}
          >
            Price
          </Typography>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px !important",
              fontWeight: 500,
              lineHeight: "18px",
            }}
          >
            {getPriceCard(
              bookingSlots || [],
              selectedDuration!,
              "Hour",
              availableStartingTimes.service?.serviceDurationMultiples!,
              1
            )}
          </Typography>
        </Box>
        <Box className="slotsList">
          {bookingSlots && bookingSlots.length > 0 ? (
            <SlotsList
              initialVisibleCount={9}
              slots={bookingSlots || []}
              selectedSlot={selectedSlot}
              addToCart={handleAddToCart}
              areAllOffPeakSlots={areAllOffPeakSlots(bookingSlots)}
              setSelectedSlot={setSelectedSlot}
              timezone={availableStartingTimes.timezone}
            />
          ) : (
            <Typography textAlign={"center"}>
              {" "}
              Sorry, we don't have any availability for the selected day please
              select another day.{" "}
            </Typography>
          )}
        </Box>
      </Box>
    </CompanySelectorWrapper>
  ) : (
    <></>
  );
};

export default CompanySelector;
