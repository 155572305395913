import React, { useContext } from "react";
import { PackageConfirmationWrapper } from "./style";
import PackageDetails from "../../../components/discount/package/PackageDetails";
import { Typography } from "@mui/material";
import { extractPackageDetails } from "../../../Utils/extract";
import { useSearchParams } from "react-router-dom";
import MembershipDetails from "../../../components/discount/membership/MembershipDetails";
import Heading from "../../../components/shared/Heading";

import PrimaryBtn from "../../../components/FormCollection/PrimaryBtn";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import useTitle from "../../../hooks/useTitle";

interface props { 
  title?: string;
}
const PackageConfirmation: React.FC<props> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  const { customerEmail, packageCode, type } =
    extractPackageDetails(searchParams);

  const { company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  return (
    <PackageConfirmationWrapper>
      <Heading
        heading={
          ["credit" , "duration"].includes(type || "") ? "Package Confirmation" : "Membership Confirmation"
        }
      />
      <Typography className="package-description">
        {`The Payment has been received. All the details have been sent to your email address at:`}
      </Typography>
      <Typography className="customer-email">{customerEmail}</Typography>
      {type === "credit" ? (
        <PackageDetails
          packagePrice={Number(searchParams.get("price") || "")}
          packageValue={Number(searchParams.get("totalCredit") || "")}
          packageCode={packageCode || ""}
          type={"credit"}
        />
      ) : type === "duration" ? 
      
        <PackageDetails
        packagePrice={Number(searchParams.get("price") || "")}
        packageValue={Number(searchParams.get("duration") || "")}
        packageCode={packageCode || ""}
        type={"duration"}
      />
        
      : (
        <MembershipDetails
          title={searchParams.get("packageName") || searchParams.get("voucherName") || ""}
          membershipCode={packageCode || ""}
          membershipServiceName={searchParams.get("serviceName") || ""}
          membershipPrice={Number(searchParams.get("price") || "")}
          membershipValue={Number(searchParams.get("maxUsage") || "")}
          membershipDuration={searchParams.get("days") ? Number(searchParams.get("days")): undefined}
          endDate={searchParams.get("endDate") || ""}
        />
      )}
      <Typography className="save-reminder">
        {company?.confirmationDisclaimer || "Please save the package code to use it on the checkout page of your next booking."}
      </Typography>

      <div style={{ width: "300px", margin: "20px auto" }}>
        <PrimaryBtn
          text="Back To Home"
          onClick={() => {
            window.open(company?.website || "","_self")
          }}
        />
      </div>
    </PackageConfirmationWrapper>
  );
};

export default PackageConfirmation;
