import dayjs from "dayjs";
import { useContext, useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import {
  CheckoutContext,
  CheckoutContextProps,
} from "../context/CheckoutContext";

import { getDateInTimezone, getFormattedTime, getTimezone } from "../Utils/format";
import { Addon } from "./useAddons";
import {
  AvailableStartingTimesDuration,
  AvailableStartingTimesSlot,
} from "./useAvailableStartingTimes";
import axiosInstance from "../services/axiosInstance";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Session } from "./useClassSessions";
import { getCountdownTime } from "../Utils/validate";
import { Company } from "./useCompany";
import { addCartItemsEvent } from "../Utils/gtmEvents";

export interface Booking {
  id: string;
  cartId: string;
  startTime: string;
  endTime: string;
  price: number;
  date: string;
  service: {
    serviceName: string;
    currency?: string;
    servicePrice?: number;
  };
  resource?: {
    resourceName: string;
  };
  serviceDuration: {
    durationTime: number;
    currency: string;
  };
  timeMultipleOf?: number;
  quantity: number;
  addonPurchases?: AddonPurchase[];
  newCartTotal?: number;
  customer?: {
    email: string;
    name: string;
  };
}

export interface AddonPurchase {
  paidAmount: number;
  id: string;
  linkedBookingId: string;
  cartId: string;
  addon: Addon;
  purchaseType: string;
  quantity: number;
}

interface AddToCartInput {
  selectedService: string;
  selectedResource?: string;
  selectedDuration?: AvailableStartingTimesDuration;
  selectedSlot?: AvailableStartingTimesSlot;
  selectedSession?: Session;
  quantity: number;
  addonsList?: { id: string; quantity: number }[];
  childrenInformation?: { name: string; age: number }[];
  timezone?: string;
}

export interface CustomerInformation {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  token?: string;
  issuer?: string;
}

const getStartTime = (slot: AvailableStartingTimesSlot, timezone?: string) => {
  return getFormattedTime(slot.fullDate, "HHmm", false, getTimezone(timezone));
};

const getEndTime = (
  slot: AvailableStartingTimesSlot,
  selectedDuration: AvailableStartingTimesDuration,
  timezone?: string
) => {
  return getDateInTimezone(slot.fullDate, false, getTimezone(timezone))
    .add(selectedDuration.durationTime, "minutes")
    .format("HHmm");
};

const useCart = (syncCart?: boolean) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { bookingCart, setBookingCart, countdownApi, setIsCartLoading, company, marketplaceName } =
    useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    const setCart = async () => {
      if (localStorage.getItem("cart") && !bookingCart?.length) {
        const { id, time } = JSON.parse(
          localStorage.getItem("cart") || "{id: 0, time: ''}"
        );

        // Get cart
        const data = await getCart(id);

        const bookingsCart: Booking[] = data.bookings.map(
          (booking: Booking) => {
            return {
              ...booking,
              addonPurchases: data.addonPurchases.filter(
                (addon: AddonPurchase) => addon.linkedBookingId === booking.id
              ),
            };
          }
        );

        // Get remaining time
        if (time) {
          const timeRemaining = Math.floor(
            (new Date(time).getTime() - new Date().getTime()) / 1000
          );
          if (timeRemaining > 0) {
            countdownApi.restart(
              new Date(
                new Date().setSeconds(new Date().getSeconds() + timeRemaining)
              )
            );

            setBookingCart(bookingsCart);
          } else {
            localStorage.removeItem("cart");
          }
        }
      }
    };

    if (syncCart) setCart();
  }, []);

  const addToCart = async ({
    selectedDuration,
    selectedService,
    selectedSlot,
    selectedSession,
    quantity,
    addonsList,
    childrenInformation = [],
    selectedResource,
    timezone
  }: AddToCartInput) => {
    setIsCartLoading(true);
    try {
      const { data: response } = await axiosInstance.post(`/cart/bookings`, {
        serviceId: selectedService,
        serviceDurationId: selectedDuration?.id,
        date: selectedSlot
          ? dayjs(selectedSlot.fullDate).format("DDMMYYYY")
          : "",
        startTime: selectedSlot ? getStartTime(selectedSlot, timezone) : "",
        endTime:
          selectedSlot && selectedDuration
            ? getEndTime(selectedSlot, selectedDuration, timezone)
            : "",
        quantity,
        cartId: bookingCart.length > 0 ? bookingCart[0].cartId : "",
        sessionId: selectedSession?.id,
        addonsList,
        childrenInformation,
        marketplaceName,
        resourceId: selectedResource
      });

      if (response.error === 0) {
        const currentTime = new Date();
        const newCountdown = new Date(
          currentTime.setSeconds(currentTime.getSeconds() + getCountdownTime(company!))
        );

        if (bookingCart.length === 0) {
          countdownApi.restart(newCountdown);
          localStorage.setItem(
            "cart",
            JSON.stringify({
              id: response.data.booking.cartId,
              time: newCountdown,
            })
          );
        }
        const newBooking: Booking = response.data.booking;
        newBooking.addonPurchases = response.data.addonPurchases;
        addCartItemsEvent("add_to_cart", bookingCart.concat(newBooking));
        setBookingCart((cart) => cart.concat(newBooking));
        toast("Booking slot is added to the cart", {
          theme: "colored",
          type: "success",
        });
        setIsCartLoading(false);
        return true;
      } else {
        setIsCartLoading(false);
        return false;
      }
    } catch (error) {
      setIsCartLoading(false);
      return false;
    }
  };

  const removeFromCart = async (booking: Booking) => {
    setIsCartLoading(true);
    try {
      await axiosInstance.delete(`/cart/bookings`, {
        data: {
          bookingId: booking.id,
          cartId: booking.cartId,
        },
      });

      toast("Booking slot is removed from the cart", {
        theme: "colored",
        type: "error",
      });

      const newBookingCart = bookingCart.filter(
        (_booking) => _booking.id !== booking.id
      );
      setBookingCart(newBookingCart);

      if (newBookingCart.length === 0) {
        countdownApi.pause();
        localStorage.removeItem("cart");
      }
      setIsCartLoading(false);
    } catch (error) {
      setIsCartLoading(false);
    }
  };

  const removeAddonFromCart = async (addonPurchase: AddonPurchase) => {
    setIsCartLoading(true);
    try {
      await axiosInstance.delete(`/cart/addon-purchase`, {
        data: {
          addonPurchaseId: addonPurchase.id,
          cartId: addonPurchase.cartId,
        },
      });

      toast("Addon is removed from the cart", {
        theme: "colored",
        type: "error",
      });

      const newBookingCart = bookingCart.filter(
        (_booking) => _booking.id !== addonPurchase.linkedBookingId
      );

      const linkedBooking = bookingCart.find(
        (booking) => booking.id === addonPurchase.linkedBookingId
      )!;

      const newAddonPurchases = linkedBooking?.addonPurchases?.filter(
        (_addonPurchase) => _addonPurchase.id !== addonPurchase.id
      );

      linkedBooking.addonPurchases = newAddonPurchases;

      newBookingCart.push(linkedBooking);

      setBookingCart(newBookingCart);
      setIsCartLoading(false);
    } catch (error) {
      setIsCartLoading(false);
    }
  };

  const updateAddonInCart = async (addonPurchase: AddonPurchase, newQuantity: number) => {
    setIsCartLoading(true);
    try {
      await axiosInstance.patch(`/cart/addon-purchase`, {
        addonPurchaseId: addonPurchase.id,
        cartId: addonPurchase.cartId,
        quantity: newQuantity
      });

      const newBookingCart = bookingCart.filter(
        (_booking) => _booking.id !== addonPurchase.linkedBookingId
      );

      const linkedBooking = bookingCart.find(
        (booking) => booking.id === addonPurchase.linkedBookingId
      )!;

      const newAddonPurchases = linkedBooking?.addonPurchases?.filter(
        (_addonPurchase) => _addonPurchase.id !== addonPurchase.id
      ) || [];

      addonPurchase.quantity = newQuantity;
      linkedBooking.addonPurchases = [...newAddonPurchases, addonPurchase];

      newBookingCart.push(linkedBooking);

      setBookingCart(newBookingCart);
      setIsCartLoading(false);
    } catch (error) {
      setIsCartLoading(false);
    }
  }

  const checkoutCart = async (
    packageCode?: string,
    customerInformation?: CustomerInformation
  ) => {
    setIsCartLoading(true);
    const params = {
      cartId: bookingCart.length > 0 ? bookingCart[0].cartId : "",
      voucherCode: packageCode || "",
      ...customerInformation,
      issuer: customerInformation?.issuer || "N/A",
    };
    try {
      const { data: response } = await axiosInstance.post(`/cart/checkout`, params);

      if (response.data.paymentUrl === "false" || !response.data.paymentUrl) {
        const cartId = bookingCart.length > 0 ? bookingCart[0].cartId : "";
        setBookingCart([]);
        countdownApi.pause();
        localStorage.removeItem("cart");
        const baseUrl = window.location.origin;
        const url = `${baseUrl}/confirmation?companyId=${company?.id}&cartId=${cartId}&customerEmail=${user?.email || customerInformation?.email}`
        window.location.replace(url);
      } else {
        localStorage.removeItem("cart");
        window.location.replace(response.data.paymentUrl);
      }
    } catch (error: any) {
      setIsCartLoading(false);
      toast(error?.response?.data?.message || "An error has occurred while processing your booking payment", {
        theme: "colored",
        type: "error",
      });
      console.error("Cart checkout failed", error?.response?.data?.message, params);
      throw new Error("Cart checkout failed")
    }
  };

  const getCart = async (id: string) => {
    setIsCartLoading(true);
    try {
      const { data: response } = await axiosInstance.get(`/cart/${id}`);
      setIsCartLoading(false);
      return response.data;
    } catch (error) {
      setIsCartLoading(false);
      throw error;
    }
  };

  // useMemo(() => {
  //   const setCart = async () => {
  //     if (localStorage.getItem("cart") && !bookingCart?.length) {
  //       const { id, time } = JSON.parse(
  //         localStorage.getItem("cart") || "{id: 0, time: ''}"
  //       );

  //       // Get cart
  //       const data = await getCart(id);

  //       const bookingsCart: Booking[] = data.bookings.map(
  //         (booking: Booking) => {
  //           return {
  //             ...booking,
  //             addonPurchases: data.addonPurchases.filter(
  //               (addon: AddonPurchase) => addon.linkedBookingId === booking.id
  //             ),
  //           };
  //         }
  //       );

  //       // Get remaining time
  //       if (time) {
  //         const timeRemaining = Math.floor(
  //           (new Date(time).getTime() - new Date().getTime()) / 1000
  //         );
  //         if (timeRemaining > 0) {
  //           countdownApi.restart(
  //             new Date(
  //               new Date().setSeconds(new Date().getSeconds() + timeRemaining)
  //             )
  //           );

  //           setBookingCart(bookingsCart);
  //         } else {
  //           localStorage.removeItem("cart");
  //         }
  //       }
  //     }
  //   };

  //   setCart();
  // }, [countdownApi, setBookingCart]);

  return {
    addToCart,
    checkoutCart,
    removeFromCart,
    removeAddonFromCart,
    updateAddonInCart,
    getCart,
  };
};

export default useCart;
