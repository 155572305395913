import React, { useContext, useMemo, useCallback, useState, useEffect } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { Box } from "@mui/system";
import Heading from "../../shared/Heading";
import SportsTabs from "../ServiceSelector/Tabs";
import { ServiceSelectorWrapper } from "../ServiceSelector/style";
import { Service } from "../../../hooks/useCompany";

interface IProps {
  type: "class" | "coach" | "standard";
  subType?: "fun" | "gym" | "kids" | "fitness" | "pods";
  serviceId?: string;
  branchId?: string;

  uponSelectCallback?: (value: string) => void;
}

const shouldConsiderThisService = (service: Service, type: string, subType?: string): boolean => {
  if (!service.type) return false;
  else if(type && subType) return service.type === type && service.subType === subType ;
  else if(type) return service.type === type && !service.subType;
  return false;
};

const ClassSelector: React.FC<IProps> = ({ subType, type, serviceId, branchId, uponSelectCallback }) => {
  const [isInitialSelection, setIsInitialSelection] = useState(true);
  
  const { servicesMap, branchesMap , setSelectedService, setSelectedLocation, selectedService, selectedLocation } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const locationOptions = useMemo(() => {
    if (!branchesMap || branchesMap.size === 0) return [];
    return Array.from(branchesMap.values()).map((branch) => ({
      value: branch.id,
      label: branch.name,
    }));
  }, [branchesMap]);

  const servicesOptions = useMemo(() => {
    if (!servicesMap || servicesMap.size === 0) return [];

    return Array.from(servicesMap.values())
      .filter(service => shouldConsiderThisService(service, type, subType))
      .filter(service => service.branchId === selectedLocation)
      .map(service => ({
        value: service.id,
        label: service.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [servicesMap, type, subType, selectedLocation]);

  useEffect(() => {
    if(locationOptions.length === 0) return;
    
    let newSelectedLocation = locationOptions[0]?.value;

    if (branchId && isInitialSelection && branchesMap?.has(branchId)) {
      newSelectedLocation = branchId;
    }

    if (serviceId && isInitialSelection && servicesMap?.has(serviceId)) {
      const service = servicesMap.get(serviceId)!;
      newSelectedLocation = service?.branchId ?? '';
    }

    if (newSelectedLocation) {
      setSelectedLocation(newSelectedLocation);
    }
  }, [branchId, serviceId, locationOptions, setSelectedLocation, servicesMap , branchesMap]);


  useEffect(() => {
    if (servicesOptions.length === 0) return;

    let newSelectedService = servicesOptions[0]?.value;

    if (serviceId && servicesMap?.has(serviceId) && isInitialSelection) {
      newSelectedService = servicesMap.get(serviceId)?.id ?? '';
    }

    if (newSelectedService) {
      setSelectedService(newSelectedService);
    }
  }, [serviceId, servicesMap, servicesOptions, setSelectedService]);

  const onServiceChange = useCallback((
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setIsInitialSelection(false);
    setSelectedService(value);
    uponSelectCallback?.(value);
  }, [setSelectedService, uponSelectCallback, setIsInitialSelection]);

  const OnBranchChange = useCallback((
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setIsInitialSelection(false);
    setSelectedLocation(value);
  }, [setIsInitialSelection, setSelectedLocation]);

  return (
    <ServiceSelectorWrapper>

        {branchId &&
          <Box style={{
            width: "100%",
            marginBottom: 16
          }}>
            <SportsTabs
              onChange={OnBranchChange}
              value={selectedLocation}
              data={locationOptions}
            />
          </Box>
        }
        <SportsTabs
          onChange={onServiceChange}
          value={selectedService}
          data={servicesOptions}
        />
      
    </ServiceSelectorWrapper>
  );
};

export default React.memo(ClassSelector);