import { Typography, Box } from "@mui/material";
import { calculatePrice, getCurrency } from "../../../Utils/format";
import { useContext } from "react";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import { AvailableStartingTimesSlot } from "../../../hooks/useAvailableStartingTimes";
import { areAllSlotsOfType } from "../../../Utils/validate";

interface Props {
    timeUnit: "Hour" | "Session";
    morningStartingTimes: AvailableStartingTimesSlot[];
    afternoonStartingTimes: AvailableStartingTimesSlot[];
}

const CalendarPriceCard: React.FC<Props> = ({ timeUnit, morningStartingTimes, afternoonStartingTimes }) => {

    const {
        company,
        quantity,
        selectedService,
        servicesMap,
        selectedDuration
    } = useContext(CheckoutContext) as CheckoutContextProps;

    const service = servicesMap?.get(selectedService);

    const areAllPeakSlots = areAllSlotsOfType([...morningStartingTimes, ...afternoonStartingTimes], true);
    const areAllOffPeakSlots = areAllSlotsOfType([...morningStartingTimes, ...afternoonStartingTimes], false);

    const peakPrice = calculatePrice(selectedDuration?.peakPricePerDurationMultiple!, selectedDuration?.durationTime!, timeUnit, company!, service!, quantity);
    const offPeakPrice = calculatePrice(selectedDuration?.offPeakPricePerDurationMultiple!, selectedDuration?.durationTime!, timeUnit, company!, service!, quantity);

    const currency = getCurrency(company?.currency);
    const sessionSuffix = timeUnit === "Session" ? "/Session" : "";

    const renderPriceContent = () => {
        if (areAllPeakSlots || peakPrice === offPeakPrice) {
            return <Typography className="label">{`${peakPrice} ${currency} ${sessionSuffix}`}</Typography>;
        } else if (areAllOffPeakSlots) {
            return <Typography className="label">{`${offPeakPrice} ${currency} ${sessionSuffix}`}</Typography>;
        } else {
            return (
                <>
                    <Typography className="label">{`${peakPrice} ${currency} ${sessionSuffix}`}</Typography>
                    <Typography className="label">{`Off-Peak: ${offPeakPrice} ${currency} ${sessionSuffix}`}</Typography>
                </>
            );
        }
    };


    if (peakPrice === 0 && offPeakPrice === 0) {
        return null;
    }

    return (
        <Box className="price-section">
            <Typography className="label">Price</Typography>
            {renderPriceContent()}
        </Box>
    );
};

export default CalendarPriceCard;