import React, { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useAvailableStartingTimes from "../../hooks/useAvailableStartingTimes";
import DaySelector from "../../components/Calendar/DaySelector";
import { CalendarWrapper } from "./style";
import TimeSelector from "../../components/Calendar/TimeSelector";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import ServiceSelector from "../../components/Calendar/ServiceSelector";
import QuantitySelector from "../../components/Calendar/QuantitySelector";
import useTitle from "../../hooks/useTitle";
import useCart from "../../hooks/useCart";
import dayjs from "dayjs";
import useGTMDataLayer from "../../hooks/useGTMDataLayer";
import CustomFooter from "../../components/shared/footer/CustomFooter";
import { useDaySelection } from "../../hooks/useDaySelection";
 


interface IProps {
  title: string;
}

const Calendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const { selectedPickerDate, setSelectedPickerDate } = useDaySelection();

  const {
    company,
    selectedLocation,
    selectedService,
    selectedResource,
    selectedDay,
    setSelectedDay,
    isAllResourcesOptionEnabled,
    setShouldShowServiceDropdown,
    setIsAllResourcesOptionEnabled,
    bookingCart,
    branchesMap,
    servicesMap,
    resourcesMap,
    availableStartingTimesResource,
    isCartLoading,
    setMarketplaceName
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const [searchParams] = useSearchParams();
  const branchId = searchParams.get("branchId");
  const serviceId = searchParams.get("serviceId");
  const resourceId = searchParams.get("resourceId");
  const martketplaceName = searchParams.get("marketplaceName");
  


  useEffect(() => {
    setShouldShowServiceDropdown(!!serviceId);
    setIsAllResourcesOptionEnabled(resourceId === null);
  }, []);


  useEffect(()=> {
    if(martketplaceName) setMarketplaceName(martketplaceName);
  },[martketplaceName])
 
 
  const { addToCart } = useCart();

 

  const { loading: isLoadingStartingTimes } = useAvailableStartingTimes({
    companyId: company?.id,
    branchId: selectedLocation,
    serviceId: selectedService,
    selectedDay,
    selectedResource,
    isAllResourcesOptionEnabled,
    bookingCart,
    selectedPickerDate
  });

  const service = servicesMap?.get(selectedService);

  useGTMDataLayer("view_item_list");

  return (
   
      <>
        <CalendarWrapper>
          <ServiceSelector
            branchesMap={branchesMap!}
            servicesMap={servicesMap!}
            resourcesMap={resourcesMap!}
            branchId={branchId}
            serviceId={serviceId}
            resourceId={resourceId}
          />

          <DaySelector
            selectedPickerDate={selectedPickerDate}
            setSelectedPickerDate={setSelectedPickerDate}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          />

          {service?.maxQuantity && service.maxQuantity > 1 && (
            <QuantitySelector />
          )}

          <TimeSelector
            availableStartingTimes={availableStartingTimesResource!}
            isLoadingStartingTimes={isLoadingStartingTimes || isCartLoading}
            addToCart={addToCart}
            timeUnit={company?.timeUnit == "hour" ? "Hour" : "Session"}
          />

          {company?.hasFooter && <CustomFooter />}
        </CalendarWrapper>
      </>
   
  );
};

export default Calendar;
