import { useContext, useEffect } from "react";
import ContentLoader from "../../components/shared/ContentLoader";
import { useSearchParams } from "react-router-dom";
import useCompany, { Membership } from "../../hooks/useCompany";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import Heading from "../../components/shared/Heading";
import { Typography } from "@mui/material";
import { PackagesWrapper } from "../package/packages/style";
import MembershipPurchaseDetails from "../../components/discount/membership/MembershipPurchaseDetails";
import useTitle from "../../hooks/useTitle";

interface props {
  title?: string;
}
const MemberShips: React.FC<props> = ({ title }) => {
  useTitle(title);
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;


  return (
      <>
        <Heading heading={company?.membershipHeader || "Membership"} sx={{ mt: 2 }} />
        <Typography sx={{ textAlign: "center", mb: 3 }}>
          {company?.membershipDisclaimer}
        </Typography>
        <PackagesWrapper sx={{ gap: "32px" }}>
          {company?.vouchers
            .filter((discount) => discount.type === "membership")
            .map((discount, i: number) => (
              <MembershipPurchaseDetails
                membership={discount as Membership}
                key={i}
              />
            ))}
        </PackagesWrapper>
      </>
    
  );
};

export default MemberShips;
