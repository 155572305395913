import { Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import {
  AvailableStartingTimesResource
} from "../../../hooks/useAvailableStartingTimes";
import { getResourceDisclaimer } from "../../../Utils/extract";
import ContentLoader from "../../shared/ContentLoader";
import AvailableStartingTimes from "./AvailableStartingTimes";
import DurationFilter from "./DurationFilter";
import { SlotSelectorWrapper } from "./style";


interface IProps {
  availableStartingTimes: AvailableStartingTimesResource;
  isLoadingStartingTimes: boolean;

  addToCart: any;
  showDurationFilter?: boolean;
  timeUnit: "Hour" | "Session";
  startTime?: string;
  endTime?: string;
}

const TimeSelector: React.FC<IProps> = ({
  isLoadingStartingTimes,
  availableStartingTimes,
  addToCart,
  showDurationFilter = true,
  timeUnit,
  startTime,
  endTime,
}) => {
  const theme = useTheme()
  const { company, selectedResource, slotsMap, selectedDuration } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const bookingSlots = slotsMap.get(selectedDuration?.id!)
  return (
    <SlotSelectorWrapper theme={theme}>
      <ContentLoader isLoading={isLoadingStartingTimes}>
        <>
          {showDurationFilter && (
            <DurationFilter availableStartingTimes={availableStartingTimes} />
          )}
          {bookingSlots && bookingSlots?.length > 0 ? (
            <>

              <AvailableStartingTimes
                addToCart={addToCart}
                availableStartingTimes={availableStartingTimes}
                timeUnit={timeUnit}
                startTime={startTime}
                endTime={endTime}
              />
              <Typography className="no-slots-label">
                {getResourceDisclaimer(company!, selectedResource)}
              </Typography>
            </>
          ) : (
            <Typography className="no-slots-label">
              Sorry No Slots Available for the selected day please select another
              day
            </Typography>
          )}
        </>
      </ContentLoader>
    </SlotSelectorWrapper>

    // </SlotSelectorWrapper>
  );
};

export default TimeSelector;
