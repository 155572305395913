import { toast } from "react-toastify";
import { getTimezone } from "../Utils/format";
import axiosInstance from "../services/axiosInstance";
import { Company } from "./useCompany";

export interface UserData {
  id?: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  dateOfBirth?: string;
  whereDidYouHearAboutUs?: string;
  favoriteSports?: string[];
  nationality?: string;
  email: string;
  isEmailVerified?: boolean;
  isProfileCompleted?: boolean;
  tenantId: string;
  employeeId?: string;
}

const useUser = () => {
  const createUser = async (user: UserData , sendVerificationEmail: boolean) => {
    try {
      const { data: response } = await axiosInstance.post(`/users`, {
        ...user,
        sendVerificationEmail
      });
      return response.data as UserData;
    } catch (error) {
      throw error;
    }
  };

  const getUser = async (id: string) => {
    try {
      const { data: response } = await axiosInstance.get(`/users/${id}`);
      return response.data as UserData;
    } catch (error) {
      throw error;
    }
  };

  const updateUser = async (user: UserData) => {
    try {
      const { data: response } = await axiosInstance.put(`/users/${user.id}`, {
        ...user,
      });
      return response.data as UserData;
    } catch (error) {
      throw error;
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      await axiosInstance.delete(`/users/${userId}`);
    } catch (error) {
      throw error;
    }
  };

  const sendWelcomeEmail = async (id: string) => {
    try {
      await axiosInstance.post(`/users/${id}/welcomeEmail`);
    } catch (error) {
      throw error;
    }
  };

  const reSendEmailVerification = async (email: string, company?: Company)=> {
    
      await axiosInstance.post(
        `/users/auth/resendVerificationEmail`,
        {
          tenantId: company?.tenantId,
          email,
        }
      );
      
      toast("verification email is sent sucessfully", {
        theme: "colored",
        type: "success",
      });

  }

  const isEmailExists = async (email: string, company?: Company) => {
    try {
      const { data: response } = await axiosInstance.get(
        `/users/isEmailExists`,
        {
          params: {
            tenantId: company?.tenantId,
            email,
          },
        }
      );

      return response.data;
    } catch (error) {
      return false;
    }
  };

  const checkIn = async (userId: string, company: Company , authToken: string) => {
    try {
      const { data: response } = await axiosInstance.get(
        `/users/venueCheckIn`,
        {
          params: {
            userId,
            timezone: getTimezone(company?.timezone),
          },
          headers: {
            jwt: authToken
          }
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    createUser,
    getUser,
    updateUser,
    sendWelcomeEmail,
    deleteUser,
    isEmailExists,
    checkIn,
    reSendEmailVerification
  };
};

export default useUser;
