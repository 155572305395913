import { useContext, useEffect, useState } from "react";
import axiosInstance from "../services/axiosInstance";
import { CheckoutContext, CheckoutContextProps } from "../context/CheckoutContext";

export interface Session {
  date: string;
  duration: number;
  name: string;
  startTime: string;
  id: string;
  serviceId: string;
  capacity: number;
  price: number;
  currency: string;
  available: number;
  type?: string;
}

const useClassSessions = (
  companyId: string,
  serviceId: string,
  date?: string
) => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {   setCurrentRangeAvailability } = useContext(CheckoutContext) as CheckoutContextProps;
  useEffect(() => {
    const abortController = new AbortController();
    const fetchClassSessions = async () => {
      setCurrentRangeAvailability([]);
      setIsLoading(true);
      try {
        const { data: response } = await axiosInstance.post(
          `/booking/getClassAvailableStartingTimes`,
          {
            companyId,
            serviceId,
            date,
          },
          {
            signal: abortController.signal,
          }
        );
        setSessions(response.data);
        setIsLoading(false);
      } catch (error) {
        setSessions([]);
        setIsLoading(false);
      }
    };

    if (companyId && serviceId) {
      fetchClassSessions();
    } else {
      setIsLoading(false);
    }



    return () => {
      abortController.abort();
    };
  }, [companyId, serviceId, date]);

  return { sessions, isLoading };
};

export default useClassSessions;
