import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { Box, Input, Typography } from "@mui/material";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import { useContext, useState } from "react";
import useUser from "../../hooks/useUser";
import CancelButton from "../../components/shared/cancelButton";
import useTitle from "../../hooks/useTitle";

interface props {
  title?: string;
}
const InValidEmailVerification: React.FC<props> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { reSendEmailVerification } = useUser();

  const handleClick = async () => {
    if (!email) {
      setError("Email is required");
      return;
    }
    setError("");
    setIsLoading(true);
    try {
      await reSendEmailVerification(email, company);
    } catch (err) {
      setError("Failed to resend email verification. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box width="90%" marginTop="20px">
      <Typography
        style={{
          fontSize: "18px",
          fontStyle: "normal",
          textAlign: "center",
          fontWeight: 500,
          marginBottom: 25,
        }}
      >
        There was a problem while verifying your email address. Please enter your email again.
      </Typography>

      <Input
        type="email"
        placeholder="Email Address"
        id="email"
        value={email}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
        }}
        sx={{
          border: 0,
          height: "70px",
          boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
          borderRadius: "16px",
          px: "16px",
          mb: 2,
          width: "100%",
          "&::before": {
            content: "none",
          },
        }}
        required
      />

      {error && (
        <Typography
          style={{
            fontSize: "14px",
            color: "red",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          {error}
        </Typography>
      )}

      <CancelButton
        buttonLabel="Resend Email Verification"
        onClick={handleClick}
        isLoading={isLoading}
      />

      <PrimaryBtn
        sx={{ mt: "15px" }}
        text="Back To Home"
        onClick={() => {
          navigate(`/?companyId=${company?.id}`);
        }}
      />
    </Box>
  );
};

export default InValidEmailVerification;
