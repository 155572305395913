import { useContext, useEffect } from "react";
import { CheckoutContext, CheckoutContextProps } from "../context/CheckoutContext";

const useTitle = (title?: string) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    if (company?.name) {
      document.title = title
        ? `${company.name} | ${title}`
        : company.name;
    }
  }, [title, company?.name]);
};

export default useTitle;
