import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { Box, Typography } from "@mui/material";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import { useContext } from "react";
import useTitle from "../../hooks/useTitle";

interface props {
  title?: string;
}
const EmailVerificationConfirmation: React.FC<props> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;


  return (
    <Box width={"90%"} marginTop={"20px"}>
      <Typography
        style={{
          fontSize: "18px",
          fontStyle: "normal",
          textAlign: "center",
          fontWeight: 500,
        }}
      >
        Thank you! you are now verified
      </Typography>
      <PrimaryBtn
        sx={{ mt: "15px" }}
        text="Back To Home"
        onClick={() => {
          navigate(`/?companyId=${company?.id}`);
        }}
      />
    </Box>
  );
};

export default EmailVerificationConfirmation;
