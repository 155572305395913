import { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { CheckoutContextProps , CheckoutContext } from '../context/CheckoutContext';


export const useDaySelection = () => {

  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");

  const [selectedPickerDate, setSelectedPickerDate] = useState<Date>(new Date());

  const {  setSelectedDay } = useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    const today = dayjs().startOf('day');
    const currentPickerDate = dayjs(selectedPickerDate).startOf('day');
    const isInitialRender = currentPickerDate.isSame(today, 'day');

    if (isInitialRender && date) {
      const formattedDate = dayjs(date, "DD/MM/YYYY");
      setSelectedDay(formattedDate.format("DD/MM/YYYY"));
      setSelectedPickerDate(formattedDate.toDate());
    } else {
      setSelectedDay(currentPickerDate.format("DD/MM/YYYY"));
    }
  }, [date, setSelectedDay, selectedPickerDate]);

  return { selectedPickerDate, setSelectedPickerDate };
};