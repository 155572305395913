import { List, ListItem, Stack } from "@mui/material";
import React from "react";
import Heading from "../../components/shared/Heading";
import useTitle from "../../hooks/useTitle";

const CustomizedListItem = ({ children }: any) => (
  <ListItem style={{ fontFamily: "Roboto" }}>{children}</ListItem>
);

interface props {
  title?: string;
}
const CancellationPolicy: React.FC<props> = ({ title }) => {
  useTitle(title);
  return (
    <Stack sx={{ marginTop: 2 }}>
      <Heading heading="Cancellation Policy" />

      <List>
        <CustomizedListItem>
          Bookings can only be cancelled online and at least 3 days prior to the
          booking date. The refunded amount will be credited to your profile in
          the form of DSW package and can be used for any future bookings at
          Dubai Sports World (DSW). The credits are only valid for the current
          year and cannot be carried to the next year.
        </CustomizedListItem>
        <CustomizedListItem>
          Credits will be applied to the original booking customer’s account
          only and cannot be transferred between accounts or used to pay for
          other customers.
        </CustomizedListItem>
        <CustomizedListItem>
          No cash refunds are available. Credits cannot be exchanged for cash.
        </CustomizedListItem>
        <CustomizedListItem>
          DSW packages and Dubai Kids World Membership once purchased is not
          transferable and cannot be cancelled.
        </CustomizedListItem>
        <CustomizedListItem>
          We reserve the right to amend these terms and conditions at any time,
          without prior notice, and at our sole discretion.
        </CustomizedListItem>
      </List>
    </Stack>
  );
};

export default CancellationPolicy;
