import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import useTitle from "../../hooks/useTitle";

import { CancellationWrapper } from "./style";
import CartCheckoutDetails from "../../components/cart/CartCheckoutDetails";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import Heading from "../../components/shared/Heading";

interface IProps {
  title: string;
}
const Cancellation: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  const cartId = searchParams.get("cartId");

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;


  return (
    <CancellationWrapper>
      <Heading heading="Booking Cancellation" sx={{ marginBottom: "32px" , marginTop: "32px"}}/>
      {cartId && <CartCheckoutDetails cartId={cartId}/>}
      <div style={{ width: "100%", marginBottom: "32px" , marginTop: "32px" }}>
        <PrimaryBtn
          text="Back To Home"
          onClick={() => {
            window.open(company?.website || "","_self")
          }}
        />
      </div>
    </CancellationWrapper>
  );
};

export default Cancellation;
