import React, { useContext, useState } from "react";

import dayjs from "dayjs";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import useTitle from "../../hooks/useTitle";
import useAvailableStartingTimes from "../../hooks/useAvailableStartingTimes";
import useCart from "../../hooks/useCart";
import { KidsCalendarWrapper } from "./style";
import DaySelector from "../../components/Calendar/DaySelector";
import ClassSelector from "../../components/Calendar/ClassSelector";
import TimeSelector from "../../components/Calendar/TimeSelector";
import QuantitySelector from "../../components/Calendar/QuantitySelector";
import { useDaySelection } from "../../hooks/useDaySelection";
interface KidsAreaCalendarProps {
  title: string;
}

const KidsAreaCalendar: React.FC<KidsAreaCalendarProps> = ({ title }) => {
  useTitle(title);
  const { addToCart } = useCart();
  const checkoutContext = useContext(CheckoutContext) as CheckoutContextProps;
  const { selectedPickerDate, setSelectedPickerDate } = useDaySelection();

  const { loading: isLoadingStartingTimes } = useAvailableStartingTimes({
    companyId: checkoutContext.company?.id,
    branchId: checkoutContext.selectedLocation,
    serviceId: checkoutContext.selectedService,
    selectedDay: checkoutContext.selectedDay,
    selectedResource: checkoutContext.selectedResource,
    isAllResourcesOptionEnabled: checkoutContext.isAllResourcesOptionEnabled,
    bookingCart: checkoutContext.bookingCart,
    selectedPickerDate
  });

  const service = checkoutContext.servicesMap?.get(checkoutContext.selectedService);

  return (
    <KidsCalendarWrapper>
      <ClassSelector type="standard" subType="kids" />
      <DaySelector
        selectedPickerDate={selectedPickerDate}
        setSelectedPickerDate={setSelectedPickerDate}
        setSelectedDay={checkoutContext.setSelectedDay}
        selectedDay={checkoutContext.selectedDay}
      />
      {service?.maxQuantity && service.maxQuantity > 1 && <QuantitySelector />}
      <TimeSelector
        availableStartingTimes={checkoutContext.availableStartingTimesResource!}
        isLoadingStartingTimes={isLoadingStartingTimes || checkoutContext.isCartLoading}
        addToCart={addToCart}
        showDurationFilter={true}
        timeUnit="Hour"
        startTime={service?.workingHours?.start}
        endTime={service?.workingHours?.end}
      />
    </KidsCalendarWrapper>
  );
};

export default KidsAreaCalendar;
