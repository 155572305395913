import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import useClassSessions from "../../hooks/useClassSessions";
import useTitle from "../../hooks/useTitle";
import ClassSelector from "../../components/Calendar/ClassSelector";
import DaySelector from "../../components/Calendar/DaySelector";
import ClassSessionSelector from "../../components/Calendar/ClassSessionSelector";
import { FunActivitiesCalendarWrapper } from "./style";
import CustomFooter from "../../components/shared/footer/CustomFooter";
import { useDaySelection } from "../../hooks/useDaySelection";

interface IProps {
  title: string;
}

const FunActivitiesCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");

  const { selectedPickerDate, setSelectedPickerDate } = useDaySelection();

  const { selectedService, company, selectedDay, setSelectedDay } =
    useContext(CheckoutContext) as CheckoutContextProps;

  const { isLoading: isLoadingSessions, sessions } = useClassSessions(
    company?.id || "",
    selectedService,
    dayjs(selectedDay, "DD/MM/YYYY").format("YYYYMMDD")
  );

  



  return (
    <FunActivitiesCalendarWrapper>
      <ClassSelector type="class" subType="kids" serviceId={serviceId || ""} />
      <DaySelector
        selectedPickerDate={selectedPickerDate}
        setSelectedPickerDate={setSelectedPickerDate}
        setSelectedDay={setSelectedDay}
        selectedDay={selectedDay}
      />

      <ClassSessionSelector
        sessions={sessions}
        isLoading={isLoadingSessions}
        type="kids"
      />

      {company?.hasFooter && <CustomFooter />}
    </FunActivitiesCalendarWrapper>
  );
};

export default FunActivitiesCalendar;
