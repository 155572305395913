import styled from "@emotion/styled";

export const AddonItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 6px 40px rgba(208, 208, 208, 0.2);
  padding: 12px;

  .chip-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
  }

  .chip {
    background-color: #FDE9F1;
    border: none;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    img {
      width: auto;
      max-width: 100%;
      height: 100px;
      object-fit: contain;
      margin-top: 10px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .addon-name {
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 4px;
  }

  .addon-price {
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    color: #808080;
    margin-bottom: 12px;
  }

  .quantity-stack {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .quantity-label {
    font-size: 10px;
    line-height: 17px;
    font-weight: 100;
    color: #808080;
    margin-right: 20px;
  }

  .quantity-select {
    border: 0;
    padding-left: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: 100;
    height: 23px;
    box-shadow: 0 6px 40px rgba(208, 208, 208, 0.2);
    border-radius: 8px;

    &::before {
      content: none;
    }

    .MuiSelect-select {
      padding: 0;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
`;