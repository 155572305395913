import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useMemo } from "react";
import { DaySelectorHeaderWrapper } from "./style";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomizedDatePicker from "../../DatePicker";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

interface IProps {
  selectedPickerDate: Date;
  setSelectedPickerDate: (date: Date) => void;
  setSelectedDay: (day: string) => void;
  onlySevenDaysInAdvance?: boolean;
}

const DaySelectorHeader: React.FC<IProps> = ({
  selectedPickerDate,
  setSelectedPickerDate,
  setSelectedDay,
  onlySevenDaysInAdvance,
}) => {
  const { selectedService, servicesMap } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const service = servicesMap?.get(selectedService);

  const handlePrevWeek = () => {
    const today = dayjs();
    let updatedSelectedPickerDate = dayjs(selectedPickerDate).subtract(
      7,
      "day"
    );
    updatedSelectedPickerDate = updatedSelectedPickerDate.isAfter(today)
      ? updatedSelectedPickerDate
      : today;
    setSelectedPickerDate(updatedSelectedPickerDate.toDate());
    setSelectedDay(updatedSelectedPickerDate.format("DD/MM/YYYY"));
  };

  const handleNextWeek = () => {
    const updatedSelectedPickerDate = dayjs(selectedPickerDate).add(7, "day");
    setSelectedPickerDate(updatedSelectedPickerDate.toDate());
    setSelectedDay(updatedSelectedPickerDate.format("DD/MM/YYYY"));
  };

  const isBackDisabled = useMemo(() => {
    return dayjs(selectedPickerDate)
      .subtract(7, "day")
      .startOf("day")
      .isBefore(dayjs().startOf("day"));
  }, [selectedPickerDate]);

  const isNextDisabled = useMemo(() => {
    if (!service?.advanceBookingDays) return false;
    return dayjs(selectedPickerDate)
      .add(7, "day")
      .startOf("day")
      .isAfter(dayjs().add(service?.advanceBookingDays!, "day"));
  }, [selectedPickerDate, service?.advanceBookingDays]);

  const getMonths = useMemo(() => {
    const months = new Set();

    for (let i = 0; i < 7; i++) {
      months.add(dayjs(selectedPickerDate).add(i, "day").format("MMM"));
    }

    const monthsArray = Array.from(months);

    return monthsArray.join(" | ");
  }, [selectedPickerDate]);

  return (
    <>
      <DaySelectorHeaderWrapper>
        <Box className="arrow-icon">
          <Button onClick={handlePrevWeek} disabled={isBackDisabled || onlySevenDaysInAdvance}>
            <ArrowBackIcon />
          </Button>
        </Box>
        <Box className="day-selector-calendar" sx={{ width: "100%" }}>
          <Typography
            className="month-label"
            sx={{
              fontFamily: "Inter",
              fontSize: "24px !important",
              fontWeight: 500,
              lineHeight: "28.8px",
            }}
          >
            {getMonths}
          </Typography>
        </Box>
        {!onlySevenDaysInAdvance && (
          <CustomizedDatePicker
            selectedPickerDate={selectedPickerDate}
            setSelectedPickerDate={setSelectedPickerDate}
            setSelectedDay={setSelectedDay}
            />
        )}
        <Box className="arrow-icon">
          <Button onClick={handleNextWeek} disabled={isNextDisabled || onlySevenDaysInAdvance}>
            <ArrowForwardIcon />
          </Button>
        </Box>
      </DaySelectorHeaderWrapper>
    </>
  );
};

export default DaySelectorHeader;
