import React, { useContext, useState } from "react";
import DaySelector from "../../components/Calendar/DaySelector";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";

import useTitle from "../../hooks/useTitle";

import ClassSelector from "../../components/Calendar/ClassSelector";
import dayjs from "dayjs";
import { CoachCalendarWrapper } from "./style";
import Heading from "../../components/shared/Heading";
import TimeSelector from "../../components/Calendar/TimeSelector";
import useAvailableStartingTimes from "../../hooks/useAvailableStartingTimes";
import useCart from "../../hooks/useCart";
import { useDaySelection } from "../../hooks/useDaySelection";

interface IProps {
  title: string;
}

const CoachCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const { selectedPickerDate, setSelectedPickerDate } = useDaySelection();

  const {
    selectedService,
    company,
    selectedDay,
    setSelectedDay,
    selectedLocation,
    selectedResource,
    isAllResourcesOptionEnabled,
    availableStartingTimesResource,
    bookingCart,
    setQuantity,
    isCartLoading,
  } = useContext(CheckoutContext) as CheckoutContextProps;

 

 
  const { addToCart } = useCart();

 

  const { loading: isLoadingStartingTimes } = useAvailableStartingTimes({
    companyId: company?.id,
    branchId: selectedLocation,
    serviceId: selectedService,
    selectedDay,
    selectedResource,
    isAllResourcesOptionEnabled,
    bookingCart,
    selectedPickerDate
  });

  setQuantity(1);
  return (
    
      <CoachCalendarWrapper>
        <Heading heading="Book a Coach" />
        <ClassSelector type="coach" />

        <DaySelector
          selectedPickerDate={selectedPickerDate}
          setSelectedPickerDate={setSelectedPickerDate}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        />

        <TimeSelector
          availableStartingTimes={availableStartingTimesResource!}
          isLoadingStartingTimes={isLoadingStartingTimes || isCartLoading}
          addToCart={addToCart}
          showDurationFilter={true}
          timeUnit="Session"
        />
      </CoachCalendarWrapper>
   
  );
};

export default CoachCalendar;
