import dayjs from "dayjs";
import { Booking } from "../hooks/useCart";
import { getCurrency } from "./format";

export const addCartItemsEvent = (
  eventName: string,
  bookings: Booking[],
  extraData: any = {}
) => {
  console.log(`Event triggered: ${eventName} with bookings:`, bookings);
  window.dataLayer?.push({
    event: eventName,
    ecommerce: {
      ...extraData,
      items: bookings.map((booking) => ({
        item_id: booking.id,
        item_name: `${booking.service.serviceName}-${
          booking.serviceDuration.durationTime
        }-${dayjs(booking.date + booking.startTime, "YYYYMMDDHHmm").format(
          "DD/MM/YYYY HH:mm"
        )}`,
        item_category: booking.service.serviceName,
        price: booking.price,
        quantity: booking.quantity,
        ...(booking.addonPurchases?.length && {
          addons: booking.addonPurchases?.map((purchase) => ({
            addon_id: purchase.addon.id,
            addon_name: purchase.addon.name,
            addon_price: purchase.addon.price,
            addon_quantity: purchase.quantity,
            paid_amount: purchase.quantity * purchase.addon.price,
          })),
        }),
      })),
    },
  });
};


export function triggerPurchaseEvent(bookings: Booking[], cart: any, cartId: string, currency: string) {
  setTimeout(
    () =>
      addCartItemsEvent("purchase", bookings, {
        transaction_id: cartId,
        currency: getCurrency(currency),
        value: cart?.newCartTotal,
        quantity: bookings.length,
      }),
    3000
  );
}