import React, { useContext, useState } from "react";

import { MembershipPurchaseWrapper } from "./style";

import { useSearchParams } from "react-router-dom";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

import usePackage from "../../../hooks/usePackage";

import CheckoutForm from "../../../components/Checkout/CheckoutForm";
import MembershipDetails from "../../../components/discount/membership/MembershipDetails";
import { CustomerInformation } from "../../../hooks/useCart";
import useTitle from "../../../hooks/useTitle";


interface props {
  title?: string;
}
const MembershipPurchase: React.FC<props> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();

  const membershipServiceName = searchParams.get("membershipServiceName");
  const membershipPrice = Number(searchParams.get("membershipPrice"));
  const membershipName = searchParams.get("membershipName");
  const membershipId = searchParams.get("membershipId")
  const membershipDuration = searchParams.get("membershipDuration");
  const { purchasePackage } = usePackage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const handleClick = async (customerInformation?: CustomerInformation) => {
    try {
      setIsLoading(true);
      const response = await purchasePackage(
        company?.id!,
        membershipId!,
        customerInformation
      );
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <MembershipPurchaseWrapper>
      <MembershipDetails
        membershipServiceName={membershipServiceName || ""}
        membershipPrice={membershipPrice}
        membershipDuration={membershipDuration ? Number(membershipDuration): undefined}
        title={membershipName || ""}
      />
      <CheckoutForm
        handleClick={handleClick}
        isLoading={isLoading}
        totalPrice={membershipPrice}
      />
    </MembershipPurchaseWrapper>
  );
};

export default MembershipPurchase;
