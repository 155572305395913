import React, { useContext, useMemo, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  InputLabel,
  useTheme,
  IconButton,
  Stack,
  Switch,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import countryList from "react-select-country-list";
import PrimaryBtn from "../../FormCollection/PrimaryBtn";
import Heading from "../../shared/Heading";
import FormSelect from "../../FormCollection/FormSelect";
import FormInput from "../../FormCollection/FormInput";
import PhoneFormInput from "./PhoneFormInput";
import { IFormTypes, IFormUIProps } from "./Types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Genders, sports, whereDidYouHearAboutUs } from "./data";
import { Controller, useFieldArray } from "react-hook-form";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ChildInput from "../../KidsBooking/ChildInput";
import AddIcon from "@mui/icons-material/Add";

const FormUI: React.FC<IFormUIProps> = ({
  onSubmit,
  form,
  isSignupWithEmailAndPassword,
  isLoading,
}) => {
  const theme = useTheme();
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const options = useMemo(() => countryList().getData(), []);
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
  } = form;

  const [hasChildren, setHasChildren] = useState(false);
  const [hasPods, setHasPods] = useState(false);

  const { fields: childrenFields, append: appendChild } = useFieldArray<IFormTypes>({
    control,
    name: "children",
  });
  

  const { fields: podsFields, append: appendPod } = useFieldArray<IFormTypes>({
    control,
    name: "pods",
  });

  // Destructure createAccountFields from company
  const { createAccountFields } = company || {};

  return (
    <>
      <Heading
        heading={
          isSignupWithEmailAndPassword
            ? "Create a Profile"
            : "Complete Your Profile"
        }
        sx={{ marginTop: "20px" }}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: "20px",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        {createAccountFields?.firstName && (
          <FormInput
            label="First Name*"
            error={errors.firstName}
            placeholder={"Enter First Name"}
            register={register("firstName")}
            id="firstName"
          />
        )}

        {createAccountFields?.lastName && (
          <FormInput
            label="Last Name*"
            error={errors.lastName}
            placeholder={"Enter Last Name"}
            register={register("lastName")}
            id="lastName"
          />
        )}

        {createAccountFields?.employeeId && (
          <FormInput
            label="Employee Id*"
            error={errors.employeeId}
            placeholder={"Enter Employee ID"}
            register={register("employeeId")}
            id="employeeId"
          />
        )}

        {createAccountFields?.phoneNumber && (
          <PhoneFormInput control={control} error={errors["phoneNumber"]} name="phoneNumber" label="Phone Number *" />
        )}

        {createAccountFields?.gender && (
          <FormSelect
            label="Gender"
            placeholder={"gender"}
            id="gender"
            sx={{
              height: "70px",
              width: "100%",
              borderRadius: "16px",
              background: "#fff",
              boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
              fontSize: "16px",
            }}
            options={Genders}
            register={register("gender")}
            error={errors.gender}
          />
        )}

        {createAccountFields?.dateOfBirth && (
          <>
            <InputLabel
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                alignSelf: "flex-start",
                color: "#171717",
              }}
            >
              Date of Birth*
            </InputLabel>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                control={control}
                name="dateOfBirth"
                render={({ field: { ...field } }) => (
                  <DatePicker
                    {...field}
                    views={["year", "month", "day"]}
                    sx={{
                      border: 0,
                      height: "70px",
                      boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
                      borderRadius: "16px",
                      pl: "16px",
                      width: "100%",
                      "&::before": {
                        content: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                      "& .MuiOutlinedInput-input": {
                        height: "70px",
                        boxSizing: "border-box",
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <Typography
              sx={{
                fontSize: "12px",
                color: theme.palette.primary.main,
                marginTop: "8px",
              }}
            >
              {errors.dateOfBirth?.message as string}
            </Typography>
          </>
        )}

        {createAccountFields?.whereDidYouHearAboutUs && (
          <FormSelect
            label="How did you hear about us?*"
            placeholder={"whereDidYouHearAboutUs"}
            id="whereDidYouHearAboutUs"
            sx={{
              height: "70px",
              width: "100%",
              borderRadius: "16px",
              background: "#fff",
              boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
              fontSize: "16px",
            }}
            options={whereDidYouHearAboutUs}
            register={register("whereDidYouHearAboutUs")}
            error={errors.whereDidYouHearAboutUs}
          />
        )}

        {createAccountFields?.favoriteSports && (
          <FormSelect
            label="Favorite Sports*"
            placeholder={"Favorite Sports"}
            id="favoriteSports"
            sx={{
              height: "70px",
              width: "100%",
              borderRadius: "16px",
              background: "#fff",
              boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
              fontSize: "16px",
            }}
            options={sports}
            register={register("favoriteSports")}
            error={errors?.favoriteSports}
            multi
            value={watch("favoriteSports")}
          />
        )}

        {createAccountFields?.nationality && (
          <FormSelect
            label="Nationality"
            placeholder={"Nationality"}
            id="nationality"
            sx={{
              height: "70px",
              width: "100%",
              borderRadius: "16px",
              background: "#fff",
              boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
              fontSize: "16px",
            }}
            options={[{ value: "", label: "Select your option" }, ...options]}
            register={register("nationality")}
            error={errors.nationality}
          />
        )}

        {createAccountFields?.emergencyContactName && (
          <FormInput
            label="Emergency Contact Name"
            error={errors.emergencyContactName}
            placeholder={"Enter Emergency Contact Name"}
            register={register("emergencyContactName")}
            id="emergencyContactName"
          />
        )}

        {createAccountFields?.emergencyContactMobile && (
          <PhoneFormInput
            control={control}
            error={errors.emergencyContactMobile}
            name="emergencyContactMobile"
            label="Emergency Contact Mobile Number"
          />
        )}

        {createAccountFields?.groupCode && (
          <FormInput
            label="Group Code"
            error={errors.groupCode}
            placeholder={"Enter Group Code"}
            register={register("groupCode")}
            id="groupCode"
          />
        )}

        {createAccountFields?.childrenInformation && (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>Register your child (under 16)  </Typography>
              <Switch
                checked={hasChildren}
                onChange={(e) => {
                  setHasChildren(e.target.checked);
                  if (e.target.checked && childrenFields.length === 0) {
                    appendChild({ name: "", age: 0 });
                  }
                }}
              />
            </Stack>
            {hasChildren && (
              <Stack spacing={2}>
                {childrenFields.map((field, index) => (
                  <Stack key={field.id} direction="row" alignItems="center" spacing={2} justifyContent={"center"}>
                    <Box flexGrow={1}>
                      <ChildInput
                        errors={errors}
                        register={register}
                        field={field}
                        index={index}
                        fieldName="children"
                      />
                    </Box>
                    {index === childrenFields.length - 1 && (
                      <IconButton onClick={() => appendChild({ name: "", age: 0 })} sx={{marginRight: "10px"}} >
                        <AddIcon />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
          </>
        )}

        {createAccountFields?.podsInformation && (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>Are you signing up for any People of Determination?</Typography>
              <Switch
                checked={hasPods}
                onChange={(e) => {
                  setHasPods(e.target.checked);
                  if (e.target.checked && podsFields.length === 0) {
                    appendPod({ name: "", age: 0 });
                  }
                }}
              />
            </Stack>
            {hasPods && (
              <Stack spacing={2}>
                {podsFields.map((field, index) => (
                  <Stack key={field.id} direction="row" alignItems="center" spacing={2}>
                    <Box flexGrow={1}>
                      <ChildInput
                        errors={errors}
                        register={register}
                        field={field}
                        index={index}
                        fieldName="pods"
                      />
                    </Box>
                    {index === podsFields.length - 1 && (
                      <IconButton onClick={() => appendPod({ name: "", age: 0 })}>
                        <AddIcon />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Stack>
            )}
          </>
        )}

        {isSignupWithEmailAndPassword && (
          <>
            <FormInput
              label="Password*"
              error={errors.password}
              id="password"
              placeholder="Password"
              register={register("password")}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              }
            />

            <FormInput
              label="Re-enter Password*"
              error={errors.passwordConfirm}
              id="passwordConfirm"
              placeholder="Re-enter Password"
              register={register("passwordConfirm")}
              type={showConfirmPassword ? "text" : "password"}
              endAdornment={
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              }
            />
          </>
        )}

        <FormGroup sx={{ marginTop: "20px" }}>
          <FormControlLabel
            control={<Checkbox {...register("privacy")} />}
            label={
              <Typography
                sx={{
                  color: "#808080",
                  fontWeight: 100,
                  a: {
                    color: "#808080",
                    textDecoration: "none",
                    fontWeight: 700,
                  },
                }}
              >
                I agree to the{" "}
                <Link
                  to={`${company?.footerPagesLinks?.privacy}`}
                  target="_blank"
                  onClick={(e) => {
                    if (!company?.footerPagesLinks?.privacy) {
                      e.preventDefault();
                    }
                  }}
                >
                  Privacy Policy
                </Link>
                , and the
                <Link
                  to={`${company?.footerPagesLinks?.terms}`}
                  target="_blank"
                  onClick={(e) => {
                    if (!company?.footerPagesLinks?.terms) {
                      e.preventDefault();
                    }
                  }}
                >
                  {" "}
                  Terms & Conditions.
                </Link>
              </Typography>
            }
          />
          {errors.privacy && (
            <Typography
              sx={{
                fontSize: "12px",
                color: theme.palette.primary.main,
                marginTop: "8px",
              }}
            >
              {errors.privacy.message}
            </Typography>
          )}
        </FormGroup>

        {createAccountFields?.marketingConsent && (
          <FormGroup sx={{ marginTop: "20px" }}>
            <FormControlLabel
              control={<Checkbox {...register("marketingConsent")} />}
              label={
                <Typography
                  sx={{
                    color: "#808080",
                    fontWeight: 100,
                  }}
                >
                  I consent to Dubai Fitness Challenge waiver
                </Typography>
              }
            />
            {errors.marketingConsent && (
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.primary.main,
                  marginTop: "8px",
                }}
              >
                {errors.marketingConsent.message}
              </Typography>
            )}
          </FormGroup>
        )}

        {hasChildren && (
          <FormGroup sx={{ marginTop: "20px" }}>
            <FormControlLabel
              control={<Checkbox {...register("parentalWaiver")} />}
              label={
                <Typography
                  sx={{
                    color: "#808080",
                    fontWeight: 100,
                  }}
                >
                  I agree to the Parental Waiver
                </Typography>
              }
            />
            {errors.parentalWaiver && (
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.primary.main,
                  marginTop: "8px",
                }}
              >
                {errors.parentalWaiver.message}
              </Typography>
            )}
          </FormGroup>
        )}

        {Object.keys(errors).length > 0 && (
          <Typography color={"error"} textAlign={"center"}>
            {`Error in submitting form. Please check and submit again.`}
          </Typography>
        )}

        <div style={{ width: "100%", margin: "0 auto" }}>
          <PrimaryBtn isLoading={isLoading} type="submit" text={"Create Profile"} />
        </div>
      </form>
    </>
  );
};

export default FormUI;