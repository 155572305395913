import { useContext } from "react";
import ClassSelector from "../../components/Calendar/ClassSelector";
import ClassSessionSelector from "../../components/Calendar/ClassSessionSelector";

import { useSearchParams } from "react-router-dom";
import useClassSessions from "../../hooks/useClassSessions";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";

import { FunActivityCalendarWrapper } from "./style";
import useTitle from "../../hooks/useTitle";

interface props {
  title?: string;
}
const FunActivities: React.FC<props> = ({ title }) => {
  useTitle(title);
  const { selectedService, company, setQuantity } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");


  const { isLoading: isLoadingSessions, sessions } = useClassSessions(
    company?.id || "",
    selectedService
  );
  
  setQuantity(1);

  return (
    
      <FunActivityCalendarWrapper>
        <ClassSelector type="class" subType="fun" serviceId={serviceId || ""} />

        <ClassSessionSelector
          sessions={sessions}
          isLoading={isLoadingSessions}
          type="fun"
        />
      </FunActivityCalendarWrapper>
  
  );
};

export default FunActivities;
