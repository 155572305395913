import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";

export const CartBookingCardRowWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #808080;
  flex: 1;
  flex-wrap: wrap;
  height: max-content;
  margin-left: 10px;

  .time-duration {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .price {
    color: #5182FF;
    font-size: 12px;
    font-weight: bold;
    margin-left: auto;
  }

  .remove-button {
    height: 24px;
    margin-left: 10px;

    .delete-icon {
      cursor: pointer;
      color: #808080;
    }
  }
`;
