import React, { useContext } from "react";
import PricingCardRow from "../../PricingCardRow";
import { Card } from "@mui/material";
import { IPricingCardProps } from "./Types";
import { getCurrency } from "../../../Utils/format";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";

const PricingCard: React.FC<IPricingCardProps> = ({
  subTotal,
  discount,
  total,
  vat,
  isPaid = false,
}) => {
  const { company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  if (subTotal === 0 && discount === 0) {
    return null;
  }

  return (
    <Card
      sx={{
        width: "100%",
        padding: "7px 19px 19px",
        borderRadius: "16px",
        boxShadow: "0 6px 40px rgba(208, 208, 208, 0.2)",
        marginTop: "15px",
      }}
    >
      <PricingCardRow
        label="Total (including VAT)"
        value={subTotal}
        currency={getCurrency(company?.currency)}
        isDiscount={false}
      />

      <PricingCardRow
        label="Discount"
        value={discount}
        currency={getCurrency(company?.currency)}
        isDiscount={true}
      />

      <PricingCardRow
        label={isPaid ? "Total Paid" : "Total to be paid"}
        value={total}
        currency={getCurrency(company?.currency)}
        isDiscount={false}
      />
    </Card>
  );
};

export default PricingCard;
