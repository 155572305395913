import {
  BaseUrlToCompany,
  DSW_COMPANY_ID,
  DUBAI_FITNESS_CHALLENGE_COMPANY_ID,
  WINTER_CAMP_COMPANY_ID,
} from "../../Utils/constants";
import { useSearchParams } from "react-router-dom";
import WinterCampHome from "./WinterCampHome";
import DubaiSportsWorldHome from "./DubaiSportsWorldHome";
import DubaiFitnessChallengeHome from "./DubaiFitnessChallengeHome";
import useTitle from "../../hooks/useTitle";

interface props {
  title?: string;
}
const Home: React.FC<props> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  let companyId = searchParams.get("companyId") || "";
  if (!companyId) {
    companyId = BaseUrlToCompany.get(window.location.origin) || "";
  }

  if (companyId === DSW_COMPANY_ID) {
    return <DubaiSportsWorldHome />;
  } else if (companyId === WINTER_CAMP_COMPANY_ID) {
    return <WinterCampHome />;
  } else if (companyId === DUBAI_FITNESS_CHALLENGE_COMPANY_ID) {
    return <DubaiFitnessChallengeHome />;
  } else {
    return <> </>;
  }
};

export default Home;
