import React, { useContext } from "react";
import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { SideBarHeaderProps } from "./Type";
import { CheckoutContext, CheckoutContextProps } from "../../../../context/CheckoutContext";

const SideBarHeader: React.FC<SideBarHeaderProps> = ({ toggleDrawer }) => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="default"
        aria-label="menu"
        onClick={toggleDrawer}
        sx={{ height: "48px" }}
      >
        <MenuIcon />
      </IconButton>
      <Box flex={1} textAlign={"center"}>
        <img src={company?.logo} alt={`${company?.name} Logo`} width={"100"} />
      </Box>
    </>
  );
};

export default SideBarHeader;
