import { Button, Typography, useTheme } from "@mui/material";
import React, { useContext } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

import { SlotConfirmationWrapper } from "./style";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import dayjs from "dayjs";
import { Service } from "../../../hooks/useCompany";
import { getCurrency } from "../../../Utils/format";
import { AvailableStartingTimesSlot } from "../../../hooks/useAvailableStartingTimes";

interface IProps {
  addToCart: any;
  setAnchorEl: any;
  selectedSlot?: AvailableStartingTimesSlot;
}
const SlotConfirmation: React.FC<IProps> = ({ addToCart, setAnchorEl , selectedSlot }) => {
  const theme = useTheme();

  const {
    company,
    selectedService,
    servicesMap,
    quantity,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const service: Service | undefined = servicesMap?.get(selectedService);

  const totalPrice = selectedSlot?.price! * quantity;

  return (
    <SlotConfirmationWrapper
      className="slot-confirmation"
      sx={{ padding: "0 !important", alignItems: "flex-start !important" }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          marginBottom: "5px",
          marginX: "auto",
          color: "#33334F",
        }}
      >
        Confirmation
      </Typography>

      <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#33334F" }}>
        Slot
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 300,
          marginBottom: "5px",
          color: "#808080",
        }}
      >
        {dayjs(selectedSlot?.fullDate).format("MMM D, YYYY")}
      </Typography>

      <Typography sx={{ fontSize: "16px", fontWeight: 500, color: "#33334F" }}>
        Price
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 300,
          marginBottom: "6px",
          color: "#808080",
        }}
      >
        {totalPrice === 0 ? "Free" : `${totalPrice} ${getCurrency(company?.currency)}`}
      </Typography>

      <Button
        variant="contained"
        className="cart-button"
        startIcon={<AddShoppingCartIcon />}
        onClick={()=> {
          setAnchorEl(null);
          addToCart();
        }}
        sx={{
          background: `${theme.palette.primary.main} !important`,
          color: "#fff !important",
          borderRadius: "4px !important",
          height: "24px !important",
          fontSize: "10px",
          textTransform: "none !important",
          fontWeight: "500 !important",
        }}
      >
        Add To Cart
      </Button>
    </SlotConfirmationWrapper>
  );
};

export default SlotConfirmation;
