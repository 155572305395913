import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";
import React, { SetStateAction, useContext, useEffect, useState } from "react";
// import { InferType } from "yup";
import { useAuth } from "../../../context/AuthContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
// import { RegistrationStep } from "../../../Utils/constants";
import { getRegisterationSchema } from "../../../Utils/validate";
// import RegistrationForm from "../registrationForm";
import { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import FormUI from "./FormUI";
import Heading from "../../shared/Heading";
import FormTabs from "./FormTabs";
import SocialMediaLogin from "../SocialMediaLogin";
import { IFormTypes, ISignupProps } from "./Types";
import { RegistrationStep } from "../../../Utils/constants";
import { useNavigate } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

const SignUp: React.FC<ISignupProps> = ({
  redirectToCompleteProfile,
  currentStep,
  redirectToCalendar,
}) => {
  const isMd = useMediaQuery("(min-width:600px)");
  // const googleProvider = new GoogleAuthProvider();
  // const facebookProvider = new FacebookAuthProvider();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSocialLoading, setIsSocialLoading] = useState<boolean>(false);
  const [formType, setFormType] = useState<RegistrationStep>(
    RegistrationStep.SignUp
  );
  const { signUp, signInWithProvider, setUser, signIn } = useAuth();
  const { isEmailExists } = useUser();
  const signUpForm = useForm<IFormTypes>({
    resolver: yupResolver(
      getRegisterationSchema(formType === RegistrationStep.Login)
    ),
  });
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  useEffect(() => {
    setFormType(currentStep);
  }, [currentStep]);

  const handleEmailAndPasswordSubmit = async (data: IFormTypes) => {
    setIsLoading(true);
    if (formType === RegistrationStep.SignUp) {
      // Signup tab
      const isExists = await isEmailExists(data.email, company);
      if (isExists) {
        signUpForm.setError("email", {
          message: "Email already exists. Please log in.",
        });
      } else {
        redirectToCompleteProfile(false, data.email);
      }
    } else {
      // Login functionality

      try {
        const signInResponse = await signIn(data.email, data.password);
        await handleResponse(signInResponse);
      } catch (error) {
        console.log("DEBUG", error);
      }
    }
    setIsLoading(false);
  };
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    if (/Android/i.test(navigator.userAgent)) {
      setIsAndroid(true);
    }
  }, []);
  // Login with social media
  const handleProviderSubmit = async (
    provider: GoogleAuthProvider | FacebookAuthProvider
  ) => {
    setIsSocialLoading(true);
    const response = await signInWithProvider(provider, isAndroid);
    await handleResponse(response);
    setIsSocialLoading(false);
  };

  // Handle cases of response after login
  const handleResponse = async (signInResponse: any) => {
    if (signInResponse.error) {
      signUpForm.setError("password", {
        message: "Incorrect username or password",
      });
    } else if (!signInResponse.isEmailVerified) {
      signUpForm.setError("password", {
        message: "Email not verified, please check your email.",
      });
    } else if (!signInResponse.isProfileCompleted) {
      setUser(signInResponse);
      redirectToCompleteProfile(true);
      toast("Please Complete Your Profile", {
        type: "info",
      });
    } else {
      setUser(signInResponse);

      if (redirectToCalendar) navigate(`/?companyId=${company?.id}`);
    }
  };

  // Switch between Signup and Login
  const onChangeFormHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: RegistrationStep
  ) => {
    setFormType(value);
  };

  return (
    <Box sx={{ backgroundColor: "white", borderRadius: "8px", width: "100%" }}>
      {/* Tabs */}
      <FormTabs formType={formType} onChangeFormHandler={onChangeFormHandler} />

      <FormUI
        form={signUpForm}
        formType={formType}
        onSubmit={handleEmailAndPasswordSubmit}
        setFormType={setFormType}
        isLoading={isLoading}
      />

      {company?.supportSocialAuthentication ? (
        <>
          <Divider
            sx={{
              color: "#666",
              fontSize: "12px",
              px: "47px",
              maxWidth: "570px",
              margin: "0 auto",
              ...(isMd && {
                fontSize: "14px",
              }),
            }}
          >
            Or Continue with social account
          </Divider>

          <SocialMediaLogin
            heading={""}
            textDivider=""
            onSubmit={handleProviderSubmit}
            isSocialLoading={isSocialLoading}
          />
        </>
      ) : (
        <></>
      )}

      <Button
        variant="text"
        onClick={() =>
          setFormType(
            formType === RegistrationStep.Login
              ? RegistrationStep.SignUp
              : RegistrationStep.Login
          )
        }
        sx={{
          padding: 0,
          textTransform: "none",
          color: "#1a1a1a",
          display: "block",
          margin: "12px auto 0",
          fontSize: "12px",
          mb: "24px",
          maxWidth: "570px",
          ...(isMd && {
            fontSize: "14px",
          }),
        }}
      >
        {formType === RegistrationStep.Login
          ? "Don’t have an account?"
          : "Already have an account?"}
        <Typography
          component="span"
          sx={{
            color: (theme) => theme.palette.primary.main,
            display: 'inline',
          }}
        >
          {formType === RegistrationStep.Login ? " Sign up" : " Login"}
        </Typography>
      </Button>
    </Box>
  );
};

export default SignUp;
