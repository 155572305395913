import { Stack, useMediaQuery } from "@mui/material";

import hero1SmImg from "../../assets/images/DFC-sm.png";
import hero1MdImg from "../../assets/images/DFC.png";

const StaticHeader = () => {
  const isMd = useMediaQuery("(min-width:600px)");

  return (
    <Stack
      component={"header"}
      sx={{
        position: "relative",
        width: "100%",
        padding: "0",
        backgroundSize: "cover",
        backgroundPosition: "center",
        alignItems: "center",
        gap: "30px",
        minHeight: "70vh",
        backgroundColor: "#E0E0E0",
        overflow: "hidden",
        "@media (max-width: 768px)": {
          minHeight: "556px",
        },
      }}
    >
      <img
        src={isMd ? hero1MdImg : hero1SmImg}
        alt="Hero"
        style={{
          width: "100%",
          height: isMd ? "70vh" : "556px"
        }}
      />
    </Stack>
  );
};

export default StaticHeader;
