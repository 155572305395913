import React from 'react';
import StaticHeader from "../../components/Home/StaticHeader";
import Categories from "../../components/Home/Categories";
import { categories, data } from "../../components/Home/FakeData";
import { useAuth } from "../../context/AuthContext";
import {
  BaseUrlToCompany,
  DSW_COMPANY_ID,
  WINTER_CAMP_COMPANY_ID,
  RegistrationStep,
} from "../../Utils/constants";
import { isValidUser } from "../../Utils/validate";
import ContentLoader from "../../components/shared/ContentLoader";
import Register from "../register";
import MakeBooking from "../../components/Home/MakeBooking";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import Footer from "../../components/shared/footer";
import { useSearchParams } from "react-router-dom";
import instaImg1 from "../../assets/instagram/1-md.jpg";
import instaImg2 from "../../assets/instagram/2-md.jpg";
import instaImg3 from "../../assets/instagram/3-md.jpg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram/icon.svg";
import ActivityOptions from '../../components/Home/ActivityOptions';
import FeaturedItems from '../../components/Home/FeaturedItems';

const DubaiFitnessChallengeHome = () => {
    const { user, isAuthLoading } = useAuth();

  return (
    <ContentLoader isLoading={isAuthLoading}>
      <Stack sx={{ width: "100%", overflow: "hidden" }}>
        <StaticHeader />

        {!isValidUser(user) && (
          <>
            <Box
              sx={{
                width: "100%",
                paddingX: "24px",
                paddingY: "50px",
                paddingBottom: "20px",
              }}
            >
              <Typography 
                variant="body1" 
                sx={{ 
                  marginBottom: "16px", 
                  textAlign: "center",
                  color: "#1e3a8a",
                  fontWeight: 500,
                  fontSize: "1.1rem",
                  lineHeight: 1.5,
                  letterSpacing: "0.5px",
                }}
              >
                Create a profile to be able to access Kite Beach - Dubai Fitness Challenge village and book courts.
              </Typography>
              <Register
                registrationStep={RegistrationStep.Login}
                redirectToCalendar={true}
              />
            </Box>
          </>
        )}

        <ActivityOptions />

        <FeaturedItems />
        
        <Footer
          socialLinks={{
            instagram: "https://www.instagram.com/dubaifitnesschallenge/",
            facebook: "https://www.facebook.com/DubaiFitnessChallenge/",
            youtube: "https://www.youtube.com/@dubaifitnesschallenge/",
            tiktok: "https://www.tiktok.com/@dxbfitchallenge/"
          }}
        />      </Stack>
    </ContentLoader>
  );
};

export default DubaiFitnessChallengeHome;
