import { Stack, useMediaQuery } from "@mui/material";

import hero1SmImg from "../../assets/hero-carousel/1-sm.jpg";
import hero1MdImg from "../../assets/hero-carousel/1.jpg";
import hero2SmImg from "../../assets/hero-carousel/2-sm.jpg";
import hero2MdImg from "../../assets/hero-carousel/2.jpg";
import hero3SmImg from "../../assets/hero-carousel/3-sm.jpg";
import hero3MdImg from "../../assets/hero-carousel/3.jpg";
import hero4SmImg from "../../assets/hero-carousel/4-sm.jpg";
import hero4MdImg from "../../assets/hero-carousel/4.jpg";

import { ReactComponent as IndicatorIcon } from "../../assets/icons/carousel-indicator.svg";
import { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";

const smImages = [hero1SmImg, hero2SmImg, hero3SmImg, hero4SmImg];
const mdImages = [hero1MdImg, hero2MdImg, hero3MdImg, hero4MdImg];

const CursoalHeader = () => {
  const isMd = useMediaQuery("(min-width:600px)");
  const [images, setImages] = useState(isMd ? mdImages : smImages);

  useEffect(() => {
    setImages(isMd ? mdImages : smImages);
  }, [isMd]);

  return (
    <Stack
      component={"header"}
      sx={{
        position: "relative",
        width: "100%",
        padding: "0",
        backgroundSize: "cover",
        backgroundPosition: "center",
        alignItems: "center",
        gap: "30px",
        minHeight: "70vh",
        backgroundColor: "#E0E0E0",
        overflow: "hidden",
        "@media (max-width: 768px)": {
          minHeight: "556px",
        },
      }}
    >
      <Carousel
        sx={{ width: "100%", position: "relative" }}
        indicators
        animation="slide"
        height={isMd ? "70vh" : "556px"}
        indicatorIconButtonProps={{
          style: {
            marginLeft: "7px",
            marginRight: "7px",
            width: "12px",
            height: "12px",
          },
        }}
        indicatorContainerProps={{
          style: {
            position: "absolute",
            bottom: "80px",
            left: "170px",
            zIndex: 99999,
            textAlign: "left",

            ...(!isMd && {
              display: "flex",
              justifyContent: "center",
              left: "unset",
            }),
          },
        }}
        IndicatorIcon={
          <>
            <IndicatorIcon />
          </>
        }
        activeIndicatorIconButtonProps={{
          style: {
            width: "18px",
            height: "18px",
          },
          className: "active-indicator",
        }}
      >
        {images.map((image, i) => (
          <img
            src={image}
            alt={`Hero ${i + 1}`}
            style={{
              width: "100%",
              height: isMd ? "70vh" : "556px",
            }}
          />
        ))}
      </Carousel>
    </Stack>
  );
};

export default CursoalHeader;
